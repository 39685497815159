<template>
  <div class="flex justify-between items-center">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">سفارشات</h1>

    <VButton class="mr-auto !w-auto" @click="scanModal = true">اسکن</VButton>
  </div>

  <InvoiceListTable
      type="inventory-carrier"
      :show-filter="showFilter"
      :key="refreshKey"/>

  <StreamBarcodeReader
      v-model="scanModal"
      @QR="openInvoice"/>
</template>

<script>

import InvoiceListTable from "@/components/Invoice/InvoiceListTable";
import StreamBarcodeReader from "@/components/Modal/General/StreamBarcodeReader";
import VButton from "@/components/General/VButton";

export default {
  name: "InvoiceList",
  components: {VButton, StreamBarcodeReader, InvoiceListTable},
  data() {
    return {
      scanModal: false,
      refreshKey: 1,
      showFilter: true,

    }
  },
  methods: {
    openInvoice(invoiceId) {
      this.scanModal = false
      this.$router.push({
        name: 'InventoryCarrierInvoiceDetail',
        params: {
          id: invoiceId
        }
      })
    }
  }
}
</script>

<style scoped>

</style>