<template>
  <VCard class="flex items-start text-sm mt-4">
    <img :src="$helper.imageURL(invoiceReturnDetail.StockDetail.Product.ImageUrl)" alt="" class="w-16">

    <div class="flex flex-col w-full mr-4">
      <!-- Product Name -->
      <div class="flex justify-between">
        <h1>{{ invoiceReturnDetail.StockDetail.Product.Name }}</h1>
      </div>

      <!-- Infos -->
      <div class="grid grid-cols-2 mt-2">
        <!-- Amount -->
        <div v-if="type === 'distributor' || type === 'QC-staff'">
          <span class="text-gray-400">تعداد کل: </span>
          <span>{{ invoiceReturnDetail.Amount }} عدد</span>
        </div>

        <!-- NoNeedAmount -->
        <div v-if="type === 'distributor'">
          <span class="text-gray-400">عدم بازگشت: </span>
          <span>{{ invoiceReturnDetail.NoNeedAmount }} عدد</span>
        </div>
        <div v-if="type === 'QC-staff'">
          <span class="text-gray-400">عدم بازگشت: </span>
          <span>{{ invoiceReturnDetail.InventoryNoNeedAmount }} عدد</span>
        </div>

        <!-- HealthyAmount -->
        <div v-if="type === 'distributor'">
          <span class="text-gray-400">تعداد سالم: </span>
          <span>{{ invoiceReturnDetail.HealthyAmount }} عدد</span>
        </div>
        <div v-if="type === 'QC-staff' || type === 'inventory-carrier'">
          <span class="text-gray-400">تعداد سالم: </span>
          <span>{{ invoiceReturnDetail.InventoryHealthyAmount }} عدد</span>
        </div>

        <!-- DefectAmount -->
        <div v-if="type === 'distributor'">
          <span class="text-gray-400">تعداد ضایع: </span>
          <span>{{ invoiceReturnDetail.DefectAmount }} عدد</span>
        </div>
        <div v-if="type === 'QC-staff' || type === 'inventory-carrier'">
          <span class="text-gray-400">تعداد ضایع: </span>
          <span>{{ invoiceReturnDetail.InventoryDefectAmount }} عدد</span>
        </div>

        <!-- PhysicalPlace -->
        <div v-if="type === 'inventory-carrier'">
          <span class="text-gray-400">محل فیزیکی: </span>
          <span>{{ invoiceReturnDetail.StockDetail.PhysicalPlace }}</span>
        </div>

        <div v-if="type === 'inventory-carrier'">
          <span class="text-gray-400">شماره پالت: </span>
          <span>{{ invoiceReturnDetail.StockDetail.StockId }}</span>
        </div>
      </div>

      <!-- Edit, Delete Buttons -->
      <div class="flex justify-between md:justify-end mt-2">
        <span v-if="this.type === 'distributor' && canEdit"
              class="badge-outline border-red-500 !px-2 cursor-pointer"
              @click="confirmRemoveInvoiceReturnItem = true">حذف</span>

        <span v-if="(type === 'QC-staff' && canEdit ) || (this.type === 'distributor' && canEdit)"
              class="badge-outline border-yellow-500 !px-2 mr-2 cursor-pointer"
              :class="{'!border-green-500 bg-green-100' : invoiceReturnDetail.edited}"
              @click="editModal = true">ویرایش</span>
      </div>

      <!-- Scan, Defect Buttons -->
      <div class="flex justify-between mt-4" v-if="type === 'inventory-carrier' && canEdit">
        <div v-if="invoiceReturnDetail.InventoryDefectAmount > 0 && !invoiceReturnDetail.DefectPlacedDate">
            <span class="border border-red-500 rounded-md px-2 py-0.5 text-xs"
                  @click="confirmDefectModal = true">ثبت ضایعات</span>
        </div>
        <div v-else-if="invoiceReturnDetail.DefectPlacedDate">
          <CheckIcon class="w-5 text-green-500"/>
        </div>

        <div v-if="invoiceReturnDetail.InventoryHealthyAmount > 0 && !invoiceReturnDetail.HealthyPlacedDate">
          <span class="border border-blue-500 rounded-md px-2 py-0.5 text-xs"
                @click="scanModal = true">اسکن سالم</span>
        </div>
        <div v-else-if="invoiceReturnDetail.HealthyPlacedDate">
          <CheckIcon class="w-5 text-green-500"/>
        </div>
      </div>

      <!-- Error -->
      <div class="mt-2 text-sm text-red-500" v-if="invoiceReturnDetail.error">{{ invoiceReturnDetail.error }}</div>

    </div>

  </VCard>

  <EditInvoiceReturnDetailModal
      v-model="editModal"
      :type="type"
      :invoice-return-detail="invoiceReturnDetail"
      @edited="edited"/>

  <StreamBarcodeReader
      v-model="scanModal"
      @QR="setIsHealthyPlaced"/>

  <ConfirmModal
      v-model="confirmRemoveInvoiceReturnItem"
      title="حذف کالا"
      text="از حذف کالای مرجوعی مطمئن هستید ؟"
      @cancel="confirmRemoveInvoiceReturnItem = false"
      @confirm="removeInvoiceReturnItem"/>

  <ConfirmModal
      v-model="confirmDefectModal"
      title="ثبت ضایعات"
      text="از ثبت ضایعات مطمئن هستید ؟"
      @cancel="confirmDefectModal = false"
      @confirm="setIsDefectPlaced"/>
</template>

<script>
import VCard from "@/components/General/VCard";
import EditInvoiceReturnDetailModal from "@/components/Modal/EditInvoiceReturnDetailModal";
import StreamBarcodeReader from "@/components/Modal/General/StreamBarcodeReader";
import {defectInvoiceReturnDetail, scanInvoiceReturnDetail} from "@/API/API";
import {CheckIcon} from "@heroicons/vue/outline";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";

export default {
  name: "InvoiceReturnProduct",
  props: ['invoiceReturnDetail', 'type', 'canEdit'],
  emits: ['edited', 'deleteItem', 'scanned'],
  components: {ConfirmModal, StreamBarcodeReader, EditInvoiceReturnDetailModal, VCard, CheckIcon},
  data() {
    return {
      editModal: false,
      scanModal: false,
      confirmDefectModal: false,
      confirmRemoveInvoiceReturnItem: false,
    }
  },
  methods: {
    edited(invoiceReturnDetail) {
      this.editModal = false
      this.$emit('edited', invoiceReturnDetail)
    },
    setIsHealthyPlaced(stockId) {
      scanInvoiceReturnDetail(this.invoiceReturnDetail.InvoiceReturnDetailId, stockId)
          .then(() => this.$emit('scanned', this.invoiceReturnDetail))
          .finally(() => this.scanModal = false)
    },
    setIsDefectPlaced() {
      defectInvoiceReturnDetail(this.invoiceReturnDetail.InvoiceReturnDetailId)
          .then(resp => this.$emit('edited', resp.data.data))
          .finally(() => this.confirmDefectModal = false)
    },
    removeInvoiceReturnItem() {
      this.$emit('deleteItem', this.invoiceReturnDetail.InvoiceReturnDetailId)
      this.confirmRemoveInvoiceReturnItem = true
    }
  }
}
</script>

<style scoped>

</style>