<template>
  <div class="intro-y flex justify-between border-b pb-2">
    <h1 class="text-lg">حواله ارسالی شماره {{ document.VoucherHdrId }}</h1>
    <h1>تاریخ: {{ document.CreateDate }}</h1>
  </div>

  <form @submit.prevent="submit">
    <div class="mt-4 pb-4 border-b last-of-type:border-0"
         v-for="data in document.Data"
         :key="data.ProductId">

      <div class="flex justify-between items-center">
        <img :src="$helper.imageURL(data.Product?.ImagePath)" alt="" class="w-20">
        <div class="mr-2 ml-auto">{{ data.Product?.Name + '  [ ' + data.TrackingId + ' - (' + data.TrackingExpr + ') ]'  }}</div>
        <div class="mx-2 badge-outline border-red-300 m-1">مقدار اولیه: {{ data.MainAmount }} عدد</div>
        <div class="mx-4">باقیمانده :{{ data.Amount }} عدد</div>
        <VInput type="number" class="w-32 ml-4" placeholder="تعداد مورد تأیید" :min="0" :max="data.Amount"
                v-model.number="data.ReceiveAmount" required/>
      </div>

        <div class="flex justify-between items-center">
           <VInput class="mt-2" placeholder="محل فیزیکی" v-model="data.PhysicalPlace"/>
           <div class="mx-4">
              <DatePicker
                  v-model="data.ProductionDate"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                  append-to="body"
                  auto-submit
                  clearable
                  :min="new Date(new Date().getTime() + -500 * 24 * 60 * 60 * 1000)"
                  placeholder="تاریخ تولید"
                  :disabled="editMode"
              />
            </div>
           <div class="mx-4">
            <DatePicker
                  v-model="data.ExpireDate"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                  append-to="body"
                  auto-submit
                  clearable
                  :min="new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)"
                  placeholder="تاریخ انقضاء"
                  :disabled="editMode"
              />
            </div>
        </div>
    </div>

    <VButton class="mt-4 px-10 w-auto mr-auto">ثبت</VButton>
  </form>

</template>

<script>
import {getDocument, storeDocument} from "@/API/API";
import "@/assets/css/pagination.css";
import VInput from "@/components/General/VInput.vue";
import VButton from "@/components/General/VButton.vue";
import DatePicker from "vue3-persian-datetime-picker";

export default {
  name: "DocumentDetail",
  components: {
    VButton,
    VInput,
    DatePicker
  },
  data() {
    return {
      document: [],

      documentData: []
    }
  },
  created() {
    this.getDocument()
  },
  methods: {
    async getDocument() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.document = (await getDocument(this.$route.params.id)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    submit() {
      let documentData = this.$helper.filterObjectArray(this.document.Data, ["TrackingId", "VoucherItmId", "ReceiveAmount", "PhysicalPlace", "ProductionDate", "ExpireDate"])

      storeDocument(this.document.DocumentId, documentData)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    }
  },
}
</script>

<style scoped>

</style>