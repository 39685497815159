<template>
  <ModalView title="اسکن QR \ BarCode">
    <StreamBarcodeReader @decode="onDecode" />
  </ModalView>
</template>

<script>
import { StreamBarcodeReader } from '@teckel/vue-barcode-reader'
import ModalView from "@/components/Modal/General/ModalView";

export default {
  emits: ['QR'],

  components: { StreamBarcodeReader, ModalView },
  methods: {
    onDecode(result) {
      this.decodedText = result;
      this.$emit('QR', result)
    },
  }
}
</script>
