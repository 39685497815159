<template>
  <ModalView
      title="ویرایش محصول" @opened="modalOpened">

    <form @submit.prevent="submit" class="mt-4">

      <div ref="dropzone" class="mt-4 dropzone flex overflow-x-scroll !p-4"></div>

      <VInput class="mt-4" type="text" placeholder="نام کالا" v-model="product.Name" required/>

      <VInput class="my-4" type="text" placeholder="توضیحات کالا" v-model="product.Description"/>

      <label>دسته بندی</label>
      <Multiselect
          dir="rtl"
          class="mt-2 mb-4 border-primary"
          v-model="product.CategoryId"
          :options="categories"
          :searchable="true"
          placeholder="انتخاب دسته بندی"
          required/>

      <label class="mt-4">برند</label>
      <Multiselect
          dir="rtl"
          class="mt-2 border-primary"
          v-model="product.BrandId"
          :options="brands"
          :searchable="true"
          placeholder="انتخاب برند"
          title="فیلد را کامل کنید "
          required/>

      <VInput type="number" class="mt-4" placeholder="سقف سفارش" v-model="product.MaxOrder"/>

      <div class="flex mt-4">
        <VInput placeholder="وزن" v-model.number="product.Weight" type="number" min="0" step=".001"/>
        <VInput class="mr-4" placeholder="حجم" v-model.number="product.Volume" type="number" min="0" step=".000001"/>
      </div>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {updateProduct} from "@/API/AdminAPI";
import Multiselect from "@vueform/multiselect";
import {Dropzone} from "dropzone";
import {getLookupCombos} from "@/API/API.js";

const productModel = {
  Name: '',
  Description: '',
  BrandId: '',
  MaxOrder: '',
  CategoryId: '',
  Images: [],
  Weight: '',
  Volume: ''
}

export default {
  name: "EditProductModal",
  emits: ['updated'],
  props: ['editProduct'],
  components: {VButton, VInput, ModalView, Multiselect},
  data() {
    return {
      brands: [],
      categories: [],
      product: {...productModel},
    }
  },
  methods: {
    submit() {
      this.product.Images = this.product.Images?.map(image => image.JCoFileId)
      updateProduct(this.editProduct.ProductId, this.product)
          .then(resp => this.$emit('updated', resp.data.data))
    },
    initDropzone() {
      let dropzone = new Dropzone(this.$refs.dropzone, {
        url: process.env.VUE_APP_STAFF_URL + 'image',
        headers: {'Authorization': this.$store.getters.token},
        maxFilesize: 1,
        dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
        dictDefaultMessage: 'انتخاب تصاویر',
        dictCancelUpload: 'لغو',
        dictRemoveFile: 'حذف',
        acceptedFiles: 'image/jpeg, image/jpg, image/png, image/webp',
        addRemoveLinks: true,
        sending(file, xhr, formData) {
          formData.append('type', 'image')
        },
        success: (file, response) => {
          file.JCoFileId = response.JCoFileId;
          this.product.Images.push({
            JCoFileId: response.JCoFileId,
            Path: response.Path
          })
        },
      });

      // Load Previous Images
      this.product.Images?.forEach(image => {
        let mockFile = {name: 'image', size: 0, JCoFileId: image.JCoFileId};
        dropzone.options.addedfile.call(dropzone, mockFile);
        dropzone.options.thumbnail.call(dropzone, mockFile, this.$helper.imageURL(image.Path));
        mockFile.previewElement.classList.add('dz-success');
        mockFile.previewElement.classList.add('dz-complete');
      })

      // Delete Image
      dropzone.on("removedfile", file => {
        let index = this.product.Images.findIndex(image => image.JCoFileId === file.JCoFileId)
        this.product.Images.splice(index, 1)
      })
    },
    async modalOpened() {
      let data = (await getLookupCombos(103010400)).data.data

      // Prepare Data for MultiSelect component
      this.brands = data.Brands.map(brand => ({
        label: brand.Name,
        value: brand.BrandId
      }))
      this.categories = data.Categories.map(category => ({
        label: category.Name,
        value: category.CategoryId
      }))
    }
  },
  watch: {
    editProduct(editProduct) {
      if (editProduct.ProductId) {

        this.product = this.$helper.cloneObject(editProduct, Object.keys(productModel))
        this.product.CategoryId = editProduct.Category?.CategoryId
        this.product.BrandId = editProduct.Brand.BrandId

        setTimeout(() => this.initDropzone(), 50)
      }
    },
  }
}
</script>

<style scoped>

</style>