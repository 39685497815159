<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">

      <!-- Confirm & Reject Buttons -->
      <div class="intro-y flex">
        <VButton @click="rejectModal = true" outline>رد سفارش</VButton>
        <VButton class="mr-4" @click="proceed(true)"
                 v-if="invoice.State?.LookupId === $helper.invoiceState.INVENTORY_CARRIER_CONFIRM">تایید دریافت
        </VButton>
      </div>

      <InvoiceDetailCard class="mt-4" :invoice="invoice"/>

      <InvoiceCustomerCard class="mt-4" :invoice="invoice"/>

      <KeyValueCard class="mt-4" title="اطلاعات تحویل گیرنده">
        <KeyValueCardItem title="نام و نام خانوادگی" :value="invoice.Address?.ReceiverFullName"/>
        <KeyValueCardItem title="شماره موبایل" :value="invoice.Address?.TelNo" last/>
      </KeyValueCard>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">

      <!-- Map -->
      <h1 class="intro-y text-lg">آدرس</h1>
      <VCard class="mt-4" noPadding>
        <div id="address-map" class="z-0 h-[15vh]"></div>

        <div class="flex text-sm my-2 mr-1">
          <LocationMarkerIcon class="w-5"/>
          <div class="mr-2" v-if="invoice.Address">
            {{ invoice.Address?.FullAddress + ' | ' + (invoice.Address?.ExtraInfo ?? '') }}
          </div>
        </div>
      </VCard>

      <!-- Remaining Payment -->
      <VCard class="flex justify-between items-center mt-4">
        <h1>
          مبلغ قابل پرداخت:
          <span class="font-bold">{{ $helper.priceFormatter(invoice.UnpaidPrice) }}</span>
        </h1>
<!--        <VButton class="w-auto" @click="POSPaymentModal = true" v-if="invoice.UnpaidPrice > 0">پرداخت</VButton>-->
      </VCard>
      <!-- Baskets -->
      <h1 class="intro-y text-lg my-4">سبد ها</h1>
      <VCard class="mt-4">
        <div class="flex justify-between mt-2"
             v-for="basket in invoice.Baskets"
             :key="basket">
          <span>{{ basket.Name }}</span>
          <span>{{ basket.Count }} عدد</span>
        </div>
      </VCard>
      <!-- Products -->
      <h1 class="intro-y text-lg my-4">محصولات</h1>
      <InvoiceProductCard
          v-for="item in invoice.Items"
          :key="item.InvoiceDetailId"
          :invoiceDetail="item"
          :canEdit="true"
          :canReturn="invoice.State?.LookupId === $helper.invoiceState.DISTRIBUTOR_CONFIRM"
          @returned="returned"
          @resetReturn="resetReturn"
          type="distributor"/>

      <!-- Confirm Button-->
      <VButton class="mt-4 mr-auto lg:w-1/2 xl:w-1/3" @click="confirmInvoiceReturnModal = true"
               v-if="hasReturnedStocks">ثبت مرجوعی
      </VButton>
      <div class="flex mt-4 mr-auto lg:w-1/2 xl:w-1/3"
           v-if="invoice.State?.LookupId === $helper.invoiceState.DISTRIBUTOR_CONFIRM &&
      invoice.DistributorAssignment.StateLId === $helper.DistributorAssignmentState.CompleteReceive">
        <VButton outline @click="returnModal = true">عدم تحویل</VButton>
        <VButton class="mr-4" @click="deliverModal = true" :disabled="hasReturnedStocks || invoice.UnpaidPrice > 0">تحویل</VButton>
      </div>

    </div>

  </div>

  <ConfirmModal
      v-model="confirmProceedModal"
      text="از دریافت سفارش مطمئن هستید ؟"
      @confirm="proceed(false)"
      @cancel="confirmProceedModal = false"/>

  <InvoiceRejectModal
      v-model="rejectModal"
      :invoice="invoice"
      @rejected="invoiceRejected"
      type="distributor"/>

  <InvoiceDeliverModal
      v-model="deliverModal"
      :invoice="invoice"
      @delivered="delivered"/>

  <InvoiceReturnModal
      v-model="returnModal"
      :invoice="invoice"
      @returned="returnModal = false"/>

  <ConfirmInvoiceReturnModal
      v-model="confirmInvoiceReturnModal"
      :invoice="invoice"
      @returned="returnSubmitted"
      @error="returnError"/>

  <StreamBarcodeReader
      v-model="scanModal"
      @QR="goToInvoice($event)"/>

  <POSPaymentModal
      v-model="POSPaymentModal"
      :invoice-id="invoice.InvoiceId"/>
</template>

<script>
import {LocationMarkerIcon} from "@heroicons/vue/outline";
import VCard from "@/components/General/VCard";
import VButton from "@/components/General/VButton";
import InvoiceProductCard from "@/components/Invoice/InvoiceProductCard";
import {getInvoice, proceedInvoice} from "@/API/API";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import InvoiceRejectModal from "@/components/Modal/InvoiceRejectModal";
import InvoiceDeliverModal from "@/components/Modal/Distributor/InvoiceDeliverModal";
import InvoiceReturnModal from "@/components/Modal/InvoiceReturnModal";
import {mapState} from "vuex"
import ConfirmInvoiceReturnModal from "@/components/Modal/Distributor/ConfirmInvoiceReturnModal";
import InvoiceDetailCard from "@/components/Invoice/InfoCard/InvoiceDetailCard";
import InvoiceCustomerCard from "@/components/Invoice/InfoCard/InvoiceCustomerCard";
import StreamBarcodeReader from "@/components/Modal/General/StreamBarcodeReader.vue";
import L from "leaflet";
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import KeyValueCard from "@/components/KeyValueCard.vue";
import KeyValueCardItem from "@/components/KeyValueCardItem.vue";
import POSPaymentModal from "@/components/Modal/Distributor/POSPaymentModal.vue";

export default {
  name: "InvoiceDetail",
  components: {
    POSPaymentModal,
    KeyValueCardItem,
    KeyValueCard,
    StreamBarcodeReader,
    InvoiceCustomerCard,
    InvoiceDetailCard,
    ConfirmInvoiceReturnModal,
    InvoiceReturnModal,
    InvoiceDeliverModal,
    InvoiceRejectModal,
    ConfirmModal,
    InvoiceProductCard,
    VButton,
    VCard,
    LocationMarkerIcon,
  },
  data() {
    return {
      invoice: {},
      confirmProceedModal: false,
      confirmInvoiceReturnModal: false,
      rejectModal: false,
      deliverModal: false,
      returnModal: false,
      scanModal: false,
      POSPaymentModal: false
    }
  },
  computed: {
    ...mapState(['user']),
    hasReturnedStocks() {
      return this.invoice.Items?.some(item => item.StockDetails?.some(stockDetail => stockDetail.SentBack))
    }
  },
  created() {
    this.getInvoice()
  },
  methods: {
    async getInvoice() {
      this.invoice = (await getInvoice(this.$route.params.id)).data.data
      setTimeout(this.initMap, 100)
    },
    initMap() {
      this.map = L.map('address-map', {
        center: [this.invoice.Address?.Lat, this.invoice.Address?.Long],
        zoom: 12,
        zoomControl: false,
        fullscreenControl: true,
      })

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
      this.markerLayer = L.layerGroup().addTo(this.map);

      let blueIcon = L.icon({
        iconUrl: require('/src/assets/img/map-marker-blue.png'),
        iconSize: [30, 30], // size of the icon
      });

      L.marker([this.invoice.Address?.Lat, this.invoice.Address?.Long], {icon: blueIcon})
          .addTo(this.markerLayer)
    },
    proceed(confirm) {
      if (confirm) {
        this.confirmProceedModal = true
        return
      }
      proceedInvoice(this.invoice.InvoiceId)
          .then(resp => {
            this.confirmProceedModal = false
            this.$infoModal.value = resp.data.message

            if (this.$route.query.QR)
              this.scanModal = true

            this.$router.replace({name: 'DistributorInvoiceListInventory'})
          })
    },
    invoiceRejected() {
      this.rejectModal = false
      this.$router.back()
    },
    returned(stockDetailReturn) {
      this.invoice.Items.forEach(item => {
        item.StockDetails.forEach(stockDetail => {
          if (stockDetail.StockDetailId === stockDetailReturn.StockDetailId) {
            stockDetail.SentBack = stockDetailReturn
          }
        })
      })
    },
    resetReturn(stockDetailReturn) {
      this.invoice.Items.forEach(item => {
        item.StockDetails.forEach(stockDetail => {
          if (stockDetail.StockDetailId === stockDetailReturn.StockDetailId) {
            delete stockDetail.SentBack
          }
        })
      })
    },
    returnError(err) {
      this.confirmInvoiceReturnModal = false
      err.response.data.errors.forEach(error => {
        let index = this.invoice.items.findIndex(item => item.InvoiceDetailId === error.InvoiceDetailId)
        this.invoice.items[index].error = error.message
      })
    },
    returnSubmitted() {
      this.confirmInvoiceReturnModal = false
      this.invoice.Items.forEach(item => {
        item.StockDetails.forEach(stockDetail => {
          delete stockDetail.SentBack
        })
      })
    },
    delivered() {
      this.$router.replace({
        name: 'DistributorInvoiceListDelivery'
      })
    },
    goToInvoice(invoiceId) {
      this.scanModal = false
      this.$router.push({
        name: 'DistributorInvoiceDetail',
        params: {id: invoiceId},
        query: {QR: true}
      })
    }
  },
  watch: {
    '$route'() {
      if (this.$route.params.id)
        this.getInvoice()
    }
  }
}
</script>

<style scoped>

</style>