<template>
   <VButton class="mr-auto !w-auto" @click="openScanModal">اسکن</VButton>

    <StreamBarcodeReader v-model="showScanModal" @QR="openDetail" />

    <div v-if="showDetail">
        <KeyValueCard >
            <KeyValueCardItem title="بارکد کالا" :value="barCode" />
            <KeyValueCardItem title="کد پالت" :value="stockInfo.StockId" />
            <KeyValueCardItem title="محصول" :value="stockInfo.ProductName" />
            <KeyValueCardItem title="محل فیزیکی" :value="stockInfo.PhysicalPlace" />
        </KeyValueCard> 

    </div>

</template>

<script>

import StreamBarcodeReader from "@/components/Modal/General/StreamBarcodeReader";
import VButton from "@/components/General/VButton";
import KeyValueCard from "@/components/KeyValueCard";
import KeyValueCardItem from "@/components/KeyValueCardItem";
 import { getStocksInfo } from "@/API/API";

export default {
    name: "ScanProductBarCode",
    components: { VButton, StreamBarcodeReader, KeyValueCard, KeyValueCardItem},
    data() {
        return {
            showFilter: true,
            showScanModal: false,
            barCode : '',
            stockInfo: '',
            showDetail: false
        }
    },
    methods: {
        openScanModal() {
            this.showScanModal = true;
        },
        async openDetail(barCode) {
            this.showScanModal = false;
            this.barCode = barCode
            this.stockInfo = (await getStocksInfo(barCode)).data.data;
            this.showDetail = true;
        },
    }
}
</script>

<style scoped></style>