<template>
  <VCard class="text-sm mt-4">
    <div class="flex">
      <img :src="$helper.imageURL(invoiceDetail.Product?.Image ?? invoiceDetail.Product?.ImageUrl)" alt="" class="w-16">

      <div class="flex flex-col w-full mr-4">
        <div class="flex justify-between">
          <h1>{{ invoiceDetail.Product?.Name }}</h1>
        </div>
        <div v-for="stockDetail in invoiceDetail.StockDetails"
             :key="stockDetail.StockDetailId">

          <div class="flex items-start text-gray-500 mt-2 justify-between">

            <span>تعداد: {{ stockDetail.Amount }}</span>

            <span v-if="stockDetail.PreviousReturnAmount > 0">مرجوع شده: {{ stockDetail.PreviousReturnAmount }}</span>
            <span v-if="stockDetail.PreviousReturn4Amount > 0">مرجوع حین تحویل: {{ stockDetail.PreviousReturn4Amount }}</span>

            <div class="flex items-start">
              <!-- Stock Info Button -->
              <InformationCircleIcon class="w-5 cursor-pointer text-blue-500"
                                     :class="{'!text-gray-500': !canEdit}"
                                     v-if="type === 'inventory-packer'"
                                     @click="showPhysicalPlace(stockDetail)"/>

              <!-- Reject Button -->
              <div class="mr-2 flex items-center text-xs">
                <XIcon class="w-5 cursor-pointer text-red-500"
                       @click="reject(stockDetail)"
                       :class="{'!text-gray-500': !canEdit || stockDetail.RejectAmount}"
                       v-if="type === 'inventory-packer' || type === 'inventory-controller'"/>
                <span v-if="stockDetail.RejectAmount">({{ stockDetail.RejectAmount }} عدد)</span>
              </div>

              <!-- Scan Button -->
              <div class="mr-2" v-if="type === 'inventory-packer'">
                <CheckIcon class="w-5 cursor-pointer text-green-500" v-if="stockDetail.ScanDate"/>
                <QrcodeIcon class="w-5 cursor-pointer text-blue-700" @click="scan(stockDetail)"
                            :class="{'!text-gray-500': !canEdit}" v-else/>
              </div>

              <!-- Return Button -->
              <button class="border border-red-500 cursor-pointer rounded-md px-2 py-0.5 text-xs disabled:bg-gray-400 disabled:border-none"
                    v-if="type === 'distributor' && canReturn" :disabled="stockDetail.Amount - stockDetail.PreviousReturnAmount <= 0"
                    :class="{'!border-green-500 bg-green-100': stockDetail.SentBack}" @click="returnStock(stockDetail)">
              مرجوع
              {{ stockDetail.SentBack ? `(${stockDetail.SentBack.Amount})` : '' }}
              </button>
            </div>

          </div>

          <div class="text-xs text-red-500 mt-1" v-if="stockDetail.RejectReason">
            {{ stockDetail.RejectReason.Name }} ({{ stockDetail.RejectAmount }} عدد)
          </div>

        </div>
      </div>
    </div>

    <div class="mt-2 text-sm text-red-500" v-if="invoiceDetail.error">{{ invoiceDetail.error }}</div>
  </VCard>

  <StockDetailRejectModal
      v-model="rejectModal"
      :stockDetail="rejectStockDetail"
      :type="type"
      @rejected="rejected"
      @replaced="replaced"
      @reset="resetReject"/>

  <StockDetailReturnModal
      v-model="returnModal"
      :stockDetail="returnStockDetail"
      @returned="returned"
      @reset="resetReturn"/>

  <StreamBarcodeReader
      v-model="scanModal"
      @QR="setIsScanned"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import {CheckIcon, InformationCircleIcon, QrcodeIcon, XIcon} from "@heroicons/vue/outline";
import StockDetailRejectModal from "@/components/Modal/StockDetailRejectModal";
import StockDetailReturnModal from "@/components/Modal/Distributor/StockDetailReturnModal";
import StreamBarcodeReader from "@/components/Modal/General/StreamBarcodeReader";
import {setIsScanned} from "@/API/API";

export default {
  name: "InvoiceProductCard",
  props: ['invoiceDetail', 'canEdit', 'type', 'canReturn'],
  emits: ['rejected', 'returned', 'scanned', 'resetReject', 'resetReturn', 'replaced'],
  components: {
    StreamBarcodeReader,
    StockDetailReturnModal,
    StockDetailRejectModal,
    VCard,
    QrcodeIcon,
    XIcon,
    CheckIcon,
    InformationCircleIcon
  },
  data() {
    return {
      rejectModal: false,
      rejectStockDetail: {},
      returnStockDetail: {},

      returnModal: false,
      scanModal: false,
      scannedStockDetail: '',
    }
  },
  methods: {
    reject(stockDetail) {
      if (this.canEdit) {
        this.rejectModal = true
        this.rejectStockDetail = stockDetail
      }
    },
    scan(stockDetail) {
      if (this.canEdit) {
        this.scanModal = true;
        this.scannedStockDetail = stockDetail
      }
    },
    showPhysicalPlace(stockDetail) {
      if (this.canEdit)
        this.$infoModal.value = `
                <div>شماره پالت: <span class="font-bold">${stockDetail.StockId}</span></div>
                <div>محل پالت: <span class="font-bold">${stockDetail.PhysicalPlace} </span></div>
            `
    },
    returnStock(stockDetail) {
      this.returnModal = true
      this.returnStockDetail = stockDetail
    },
    rejected(stockDetailReject) {
      this.rejectModal = false
      this.$emit('rejected', stockDetailReject)
    },
    replaced() {
      this.rejectModal = false
      this.$emit('replaced')
    },
    resetReject(stockDetail) {
      this.rejectModal = false
      this.$emit('resetReject', stockDetail)
    },
    returned(stockDetailReturn) {
      this.returnModal = false
      this.$emit('returned', stockDetailReturn)
    },
    resetReturn(stockDetail) {
      this.returnModal = false
      this.$emit('resetReturn', stockDetail)
    },
    setIsScanned(stockId) {
      this.scanModal = false
      setIsScanned(this.scannedStockDetail.StockDetailId, stockId)
          .then(() => this.$emit('scanned', this.scannedStockDetail.StockDetailId))
    },
  }
}
</script>

<style scoped>

</style>